import React from "react"
import Layout from "../components/Layout"
import AllIdeetipp from "../components/AllIdeetipp"
import SEO from "../components/SEO"

const IdeenTipps = () => {
  return (
    <Layout>
      <SEO title="Übersicht " />
      <main className="page">
        <h1>Ideen und Tipps</h1>
        <AllIdeetipp />
      </main>
    </Layout>
  )
}

export default IdeenTipps
